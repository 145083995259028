'use strict'

export function manuals_index(DFApp) {
    // if (!DFApp.body.hasClass("js-manuals-create"))
    //     return;

    let $product_id = $('#product_id');
    let $model_search = $(".js-search-product");

    function setup_model_controls(val) {
        if (!val || !val.data)
            val = {data: null}

        console.log($product_id)
        console.log(val.data)
        $product_id.val(val.data);
        $model_search.removeClass('uk-form-danger');

        let $a_model_link = $('#a_model_link');
        if (val.data) {
            $model_search.val(val.value);
            $a_model_link.removeClass('uk-hidden');
            $a_model_link.attr('href', val.url);
        } else {
            $model_search.val('');
            $a_model_link.addClass('uk-hidden');
        }
    }

    $('#model_clear').on('click', function (e) {
        e.preventDefault();
        setup_model_controls({data: null})
    })

    $model_search.autocomplete({
        serviceUrl: $model_search.data("product-search"),
        // при true: "E.g. if Jam returns no suggestions,
        // it will not fire for any future query that starts with Jam"
        preventBadQueries: false,
        onSelect: setup_model_controls
    })

    $model_search.on('change', function () {
        $model_search.addClass('uk-form-danger');
    })

    $.ajax({
        url: $model_search.data("product-search"),
        data: $product_id.val(),
        success: setup_model_controls
    })

    let locale = $('#locale') // input с основным языком
    let $locales = $('[name=locales]'); // чекбоксы с локалями
    let $toggle_all_locales = $('.toggle-all-locales'); // чекбокс - "все локали"
    //
    // сменился основной язык - деактивируем чекбокс, остальные активируем
    function on_main_locale_changed() {
        $locales.prop('disabled', false)
        $locales.filter('[value=' + locale.val() + ']').prop('checked', true).prop('disabled', true)
    }

    locale.on('change', function () {
        on_main_locale_changed()
    })
    on_main_locale_changed()

    // ==============================
    // Включить / выключить все языки
    // ==============================
    $("#select_deselect_all").on("change", function(event) {
        const where = $(this).data("where");
        let checked = $(this).is(":checked")
        const checkBoxes = $(where).find("input[type=checkbox]")

        checkBoxes.each(function(i, el) {
            let elChecked = $(el).is(":checked")

            if ((!elChecked && checked) || (elChecked && !checked)) {
                $(el).trigger("click")
            }
        })
    })

}