$(function() {

    // ---------------
    // Вкладка "Языки"
    // ---------------

    // Изменение настроек локализации сбрасывает выбранную локализацию из справочника
    $(".js-pkg-localization-change, .js-pkg-text-change").on("change", function (e) {
        const cleanElement = $(this).data("clean-element") || false;
        const element = $(cleanElement)

        if (cleanElement && element) {
            $(element).val("__None")
        }

        const checkSwitch = $(this).data("check-switch") || false;
        const checkElement = $(this).parents("tr").find("input[name='"+ checkSwitch + "']") || false;

        if (checkElement) {
            if ($(checkElement).is(":checked")) {
                $(checkElement).prop("checked", false)
            }
        }
    });

    // Групповая отметка
    $(":checkbox[data-pkg-group-selector]").on("change", function(event) {
        const self = $(this)
        const itemsCls = self.data("items") || false;
        const elements = $("." + itemsCls)

        elements.each((i, e) => {
            const switchData = $(e).parents("tr").find('input[name="' + $(e).data("check-switch") + '"]') || false;
            if (self.is(":checked")) {
                if (switchData) {
                    if (!$(switchData).is(":checked")) {
                        if (!$(e).is(":checked"))
                            $(e).trigger("click")
                    } else {
                    }
                }
            } else {
                if ($(e).is(":checked")) {
                    $(e).trigger("click")
                }
            }

        })

    })

    // ----------------
    // Вкладка "Тексты"
    // ----------------

    const checkSelected = (accordionItem) => {
        const hasSelected = $(accordionItem).find("input:checked").length || false
        const accordionButton = $(accordionItem).find("button.accordion-button")

        if (hasSelected > 0) {
            $(accordionButton).addClass("has-selections").removeClass("no-selections")
        } else {
            $(accordionButton).removeClass("has-selections").addClass("no-selections")
        }
    }

    // Подсветка группы
    $(".js-pkg-text-change").on("change", function(e) {
        const accordionItem = $(this).closest(".accordion-item") || false;
        checkSelected(accordionItem)
    })

    // Показ / выключение выбранных
    $("#switcherShowHideSelectedItems").on("change", function(e) {
        const checked = $(this).is(":checked")
        const accordionItems = $(".accordion-item")

        if (checked) {
            $(accordionItems).each((i, elem) => {
                // Скроем не выбранные чекбоксы
                $(elem).find("input:not(:checked)").each((ii, e) => {
                    $(e).parent(".form-check").addClass("d-none")
                })

                let btn = $(elem).find("button.accordion-button") || false;

                if (btn) {
                    if (btn.hasClass("has-selections")) {
                        // Если в группе есть отмеченные
                        if ($(btn).hasClass("collapsed")) {
                            // И группа скрыта, тогда ее откроем
                            $(btn).trigger("click")
                        }
                    } else {
                        // В группе нет отмеченных
                        if (!(btn).hasClass("collapsed")) {
                            // и группа открыта, закроем ее
                            $(btn).trigger("click")
                        }
                    }
                }
            })
        } else {
            // Просто закроем все группы
            $(accordionItems).each((i, elem) => {
                let btn = $(elem).find("button.accordion-button") || false;
                if (btn) {
                    if (!$(btn).hasClass("collapsed")) {
                        $(btn).trigger("click")
                    }
                }
                // Вернем обратно к показу скрытые чекбоксы
                $(elem).find("input:not(:checked)").each((ii, e) => {
                    $(e).parent(".form-check").removeClass("d-none")
                })
            })
        }
    })

    // ------------------------
    // Вкладка "Характеристики"
    // ------------------------
    const containersList = $("[data-df-package-property-customize]")
    containersList.each(function(index, element) {
        const container = $(element)
        const editButton = container.find("a") || false
        const switchControl = container.find(".js-property-string") || false
        const editForm = container.find(".js-property-editor") || false
        const info = switchControl.find(".js-property-string-info") || false

        editButton.on("click", function(e) {
            e.preventDefault()
            if (editForm) {
                if (editForm.hasClass("d-none")) {
                    editForm.removeClass("d-none")
                    switchControl.addClass("d-none")
                } else {
                    switchControl.removeClass("d-none")
                    editForm.addClass("d-none")
                }
            }
        })

        $(container).find("button[name=apply-result]").on("click", function(e) {
            e.preventDefault()
            const inputs = editForm.find(".js-property-editor-fields").find("input,select")

            $.ajax({
                url: $(this).data("url"),
                method: "POST",
                data: inputs.serialize(),
                success(data) {
                    const stringValue = data.as_string || false
                    const changed = data.changed || false
                    if (stringValue) {
                        editButton.html(stringValue)
                    }
                    if (!changed) {
                        info.addClass("d-none")
                    } else {
                        info.removeClass("d-none")
                    }
                    editButton.click()
                },
                error() {
                    alert("Что-то пошло не так")
                }
            })

        })
        $(container).find("button[name=revert]").on("click", function(e) {
            e.preventDefault()
            const inputs = editForm.find(".js-property-editor-fields").find("input,select")

            $.ajax({
                url: $(this).data("url"),
                data: inputs.serialize(),
                method: "POST",
                success(data) {
                    const stringValue = data.as_string || false
                    const changed = data.changed || false
                    if (stringValue) {
                        editButton.html(stringValue)
                    }
                    if (!changed) {
                        info.addClass("d-none")
                    } else {
                        info.removeClass("d-none")
                    }

                    editButton.click()

                    const returnedData = data.ret_data || false

                    if (returnedData) {
                        for (let key in returnedData) {
                            const input = $("select[name=" + key + "], input[name=" +key+ "]") || false
                            if (input) {
                                input.val(returnedData[key])
                            }
                        }
                    }
                },
                error() {
                    alert("Что-то пошло не так")
                }
            })
            // editButton.click()
        })
        $(container).find("button[name=cancel]").on("click", function(e) {
            e.preventDefault()
            editButton.click()
        })
    })

    $("input[type=checkbox].js-mark-long-block-too").on("click", function(e) {
        const tr = $(this).parents("tr")
        const long = $(tr).find("input[type=checkbox].js-full-check")
        $(long).prop("checked", $(this).is(":checked"))
    })

    // $("input[type=checkbox].js-mark-short-block-too").on("click", function(e) {
    //     if ($(this).is(":checked")) {
    //         const tr = $(this).parents("tr")
    //         const short = $(tr).find("input[type=checkbox].js-short-check")
    //         $(short).prop("checked", true)
    //     }
    // })
    // $("input[type=checkbox].js-mark-long-block-too").on("click", function(e) {
    //     if (!$(this).is(":checked")) {
    //         const tr = $(this).parents("tr")
    //         const short = $(tr).find("input[type=checkbox].js-full-check")
    //         $(short).prop("checked", false)
    //     }
    // })

    // container.each(function(number, element) {
    //     let editorContainer = $(container).find(".js-property-editor") || false
    //     const editButton = $(container).find("a").on("click", function(e) {
    //         $(e).preventDefault()
    //         if (editorContainer.hasClass("d-none")) {
    //             editorContainer.removeClass("d-none")
    //         } else {
    //             editorContainer.addClass("d-none")
    //         }
    //     })
    // })

    // --------------
    // Вкладка "Крой"
    // --------------
    // Uploader
    const preventDefaults = event => {
        event.stopPropagation()
        event.preventDefault()
    }
    const highlight = event => {
        $(event.target).addClass("highlight")
    }
    const unhighlight = event => {
        $(event.target).removeClass("highlight")
    }

    const getRefs = element => {
        const zone = $(element).closest(".upload_dropZone") || false
        const input = $(zone).find("input[type=file]") || false
        const container = $(zone).find(".upload_dropZone__content") || false
        return {zone: zone, input: input, container: container}
    }
    const handleDrop = event => {
        const dataRefs = getRefs(event.target)
        dataRefs.files = event.originalEvent.dataTransfer.files
        handleFiles(dataRefs)
    }
    const handleFiles = dataRefs => {
        let files = [...dataRefs.files]
        if (!files.length) return
        upload(dataRefs)
    }
    const upload = dataRefs => {
        if (!dataRefs || !dataRefs.files || !dataRefs.input) return
        const uploadUrl = $(dataRefs.input).data("post-url") || false
        if (!uploadUrl) return
        const name = $(dataRefs.input).data("post-name") || false
        if (!name) return

        const formData = new FormData()
        formData.append(name, dataRefs.files[0])

        // const savedContent = $(dataRefs.container).html()
        // console.log(savedContent)
        const error = $(dataRefs.container).find(".upload_dropZone__error") || false
        const upload = $(dataRefs.container).find(".upload_dropZone__upload") || false
        const loader = $(dataRefs.container).find(".upload_dropZone__loading") || false
        $(error).addClass("d-none")
        $(upload).addClass("d-none")
        $(loader).removeClass("d-none")

        $.ajax({
            url: uploadUrl,
            method: "POST",
            data: formData,
            processData: false,
            contentType: false,
            success: (data, textStatus, jqXHR) => {
                $(loader).addClass("d-none")
                $(upload).removeClass("d-none")

                // TODO: Здесь предпросмотр
                $(dataRefs.zone).replaceWith(data.preview)
                $(document).trigger("uploader-change")
            },
            error: (jqXHR, textStatus, errorThrown) => {
            }
        })
    }

    const eventHandlers = zone => {
        const dataRefs = getRefs(zone)
        if (!dataRefs.input) return

        $(zone).on("drag dragstart dragend dragover dragenter dragleave drop", preventDefaults)
        .on("dragover dragenter", highlight)
        .on("dragleave dragend drop", unhighlight)
        .on("drop", event => handleDrop(event))

        $(dataRefs.input).on("change", event => {
            dataRefs.files = event.target.files
            handleFiles(dataRefs)
        })
    }

    const eventAlreadyHandlers = zone => {
        $(zone).find(".alreadyUploaded__delete").on("click", function(event) {
            handleDelete(event, $(this))
        })
    }

    const handleDelete = (event, button) => {
        preventDefaults(event)
        const zone = $(button).parents(".alreadyUploaded")
        console.log(zone)
        const message = $(button).data("message") || false

        if (message) {
            if (!confirm(message)) return
        }

        const deleteUrl = $(button).data("url") || false;
        if (!deleteUrl) return

        $.ajax({
            url: deleteUrl,
            method: "DELETE",
            success: function(data, textStatus, jqXHR) {
                $(zone).replaceWith(data.uploader)
                $(document).trigger("uploader-change")
            },
            error: function(jqXHR, textStatus, errorThrown) {
                alert("ERROR")
            }
        })
    }

    const attachEvents = () => {
        $(".upload_dropZone").each((i, zone) => eventHandlers(zone))
        $(".alreadyUploaded").each((i, zone) => eventAlreadyHandlers(zone))
    }

    $(document).on("uploader-change", event => attachEvents())
    attachEvents()

    // Дополнительные файлы
    const filesContainer = $("#additionalFilesContainer")

    $("#newAdditionalFile").on("click", function(e) {
        // Добавить файл
        e.preventDefault()

        $.ajax({
            url: $(this).data("url"),
            success: function(data) {
                $(filesContainer).prepend(data.preview)
                $(document).trigger("uploader-change")
            },
            error: function() {
                alert("Что-то пошло не так")
            }
        })
    })

    // -----------------------------
    // Вкладка "Задание и результат"
    // -----------------------------

    $(document).on("click", ".js-delete-confirmation-button", function(e) {
        const confirmMessage = $(this).data("message") || false
        if (confirmMessage && confirm(confirmMessage)) {
            const url = $(this).data("url") || false
            const container = $(this).parents(".js-result-preview")
            if (url) {
                $.ajax({
                    method: "DELETE",
                    url: url,
                    success: function(data) {
                        $(container).replaceWith(data.preview)
                        $(document).trigger("uploader-change")
                    },
                    error: function() { alert("Что-то пошло не так") }
                })
            }
        }
    })


    // -----------------------------
    // Общий список заданий в товаре
    // -----------------------------
    $("#createLastBase, #createSelectedBase").on("click", function(e) {
        if (confirm($(this).data("msg"))) {
            if ($(this).attr("id") == "createSelectedBase") {
                alert("123")
            }

            return true;
        }
        e.preventDefault()
    })

    $("#removeSelectedTasks").on("click", function(e) {
        if (!e.isDefaultPrevented()) {
            e.preventDefault()

            let rows = []
            let values = []


            $("input[type=checkbox].js-item-check:checked").each(function(index, element) {
                values.push($(element).val())
                rows.push($(element).parents("tr"))
            })

            $.ajax({
                method: "POST",
                url: $(this).attr("href"),
                data: {
                    ids: values
                },
                success(data, textStatus, jqXHR) {
                    $(rows).each(function(i, el) {
                        $(el).remove()
                    })
                    $(":checkbox[data-group-selector]").each(function(i, el) { $(el).prop("checked", false) })
                }
            })
        }
    })

    $("#createSelectedBase").on("click", function(e) {

    })

    // -----------------------------------
    // Редактирование текстов для упаковки
    // -----------------------------------
    $("button#addTextItem").on("click", function(event) {
        event.preventDefault();
        const url = $(this).data("url")
        const container = $("tbody.js-text-item-rows") || null

        $.ajax({
            url: url,
            method: "GET",
            success: function(data) {
                if (data.preview && container) {
                    $(container).append(data.preview)
                }
                // console.log(data.preview)
            }
        })
    })

    $(document).on("click", "button.js-delete-text-item-row", function(event) {
        event.preventDefault()
        const url = $(this).data("url")
        const msg = $(this).data("message")
        const self = $(this)

        if (msg && confirm(msg)) {
            $.ajax({
                url: url,
                method: "DELETE",
                success(data, textStatus, jqXHR) {
                    self.parents("tr").remove()
                },
                error: function () {
                    alert("Что-то пошло не так!")
                }
            })
        }
    })
});
